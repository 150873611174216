<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-10">
      <!-- <div class="card-header">
          <h3 class="mb-0">Funcionários</h3>
        </div> -->

      <ul class="nav nav-pills col-md-12" role="tablist">
        <li
          v-for="(item, index) in menus"
          :key="index"
          class="col-md-3 col-6 col-sm-3 col-lg-3"
        >
          <a
            class="card nav-link rounded flex-column align-items-between"
            data-toggle="pill"
            style=""
            @click="subpastas(item.route)"
            v-if="item.ver"
          >
            <span class="nav-icon py-2 w-auto">
              <span class="svg-icon svg-icon-3x">
                <inline-svg :src="item.url" />
              </span>
            </span>
            <span class="nav-text font-size-sm font-weight-bolder text-center">
              {{ item.nome }}</span
            >
          </a>
        </li>
      </ul>

      <!-- show-checkbox-->
    </div>

    <div v-if="documento.caminho" class="card col-md-12">
      <div class="row">
        <div class="col-md-8">
          Rotas : {{ documento.caminho.replace("/", "\\") }}
        </div>
        <div class="col-md-4">
          <v-btn @click="showModalVersao()" color="info" outlined
            >Versionamento</v-btn
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8 mt-2">
        <h4>{{ documento.nome_original }}</h4>

        <div class="col-md-12 mt-2" v-if="documento.extensao === 'pdf'">
          <v-card :loading="loading">
            <div class="row col-md-12 justify-content-between mt-2 ml-1">
              <input
                class="form-control col-2"
                type="number"
                v-model="currentPageInput"
                min="1"
                :max="numPages"
                @keyup.enter="goToPage"
              />
              <v-btn class="ml-1" small @click="goToPage"
                >Ir para a página</v-btn
              >
            </div>
            <v-container>
              <v-row justify="center">
                <v-col cols="12">
                  <v-container class="max-width">
                    <v-pagination
                      :disabled="loading"
                      v-if="numPages > 0"
                      v-model="currentPage"
                      :length="numPages"
                      @input="changePage"
                      :total-visible="10"
                      circle
                    ></v-pagination>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </div>
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card
              :loading="arquivoData == null"
              :elevation="hover ? 24 : 6"
              class="mx-auto pa-6"
            >
              <!-- <viewer :images="images"> -->
              <!-- <img v-for="src in images" :key="src" :src="src" /> -->

              <div
                v-if="
                  documento.extensao === 'png' || documento.extensao === 'jpg'
                "
              >
                <v-img
                  :src="host + documento.id + '?token=' + token"
                  contain
                  max-width="750"
                ></v-img>
              </div>
              <div v-else>
                <div v-if="documento.extensao === 'mp4'">
                  <iframe
                    height="500"
                    width="100%"
                    :src="host + documento.id + '?token=' + token"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
                <div v-else>
                  <!-- Exibir o PDF usando o componente vue-pdf -->
                  <vue-pdf
                    ref="pdfViewer"
                    v-if="documento.caminho"
                    :src="arquivoData"
                    :page="currentPage"
                    @num-pages="onDocumentLoad"
                  />
                </div>
              </div>

              <!-- <img    :src="documento.caminho" alt=""> -->
              <!-- </viewer> -->
              <!-- api -->
            </v-card></template
          ></v-hover
        >
      </div>
      <div class="col-md-4 mt-5">
        <v-card max-height="400" elevation="16" class="mt-2">
          <!-- <v-btn block color="primary" @click="sele">
                      scroll to Item-20
                    </v-btn> -->
          <h3 class="pl-2 pt-2">Arquivos da Pasta:</h3>
          <v-card-text>
            <v-list max-height="300" class="overflow-y-auto" dense>
              <v-list-item-group v-model="selectedItem" color="primary">
                <v-list-item
                  :id="'list-item-' + i"
                  v-for="(item, i) in lista_pastas_doc"
                  :key="i"
                  v-show="item.icon == 'file-earmark'"
                >
                  <v-list-item-actio>
                    <v-btn
                      fab
                      small
                      depressed
                      color="primary"
                      @click="irArq(item)"
                    >
                      {{ item.id }}
                    </v-btn>
                  </v-list-item-actio>

                  <v-list-item-content>
                    <v-btn
                      class=""
                      @click="irArq(item)"
                      depressed
                      v-b-tooltip.hover
                      :title="item.nome"
                    >
                      <v-list-item-title class="text-start">
                        {{
                          item.nome.length > 17
                            ? item.nome.substring(0, 17) + "..." + item.extensao
                            : item.nome
                        }}
                      </v-list-item-title>
                    </v-btn>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn @click="irArq(item)" depressed color="#aff2f3">
                      <v-icon small> mdi-open-in-new </v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>
      </div>

      <div class="col-md-12">
        <div class="full-height bg-ligth row">
          <div class="col-md-6">
            <!-- <v-hover>
            <template v-slot:default="{ hover }">
              <v-card :elevation="hover ? 24 : 6" class="mx-auto pa-6" style="word-break: break-word">
                 -->
            <!-- <div style=" overflow-x: scroll;width:100%;" > -->

            <h1>Informacoes</h1>
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-card
                  :loading="loading"
                  :elevation="hover ? 24 : 6"
                  class="mx-auto pa-6"
                >
                  <template slot="progress">
                    <v-overlay absolute class="d-flex flex-column text-center">
                      <div>
                        <v-progress-circular size="75" indeterminate>
                          <span>Loading</span>
                        </v-progress-circular>
                      </div>
                      <div>
                        <!-- <v-btn text dark @click="loading = false" class="mt-3"
                        >Deactivate loading</v-btn
                      > -->
                      </div>
                    </v-overlay>
                  </template>

                  <div
                    class="d-flex flex-column bd-highlight mb-3 font-weight-bold"
                    style="font-size: 20px"
                  >
                    <!-- <div class="p-2 bd-highlight">
                    nome : {{ documento.nome }}
                  </div> -->
                    <div class="p-2 bd-highlight">
                      caminho : {{ documento.caminho }}
                    </div>
                    <div class="p-2 bd-highlight">
                      tamanho : {{ documento.tamanho }}
                    </div>
                    <div class="p-2 bd-highlight">
                      extensao : {{ documento.extensao }}
                    </div>
                  </div>
                </v-card>
              </template>
            </v-hover>

            <!-- extensao : {{documento.extensao}} -->

            <!-- </v-card></template
          ></v-hover> -->
          </div>
          <div class="col-md-6">
            <h1>Arquivo</h1>

            <v-hover>
              <template v-slot:default="{ hover }">
                <v-card
                  :loading="loading"
                  :elevation="hover ? 24 : 6"
                  class="mx-auto pa-6"
                >
                  <template slot="progress">
                    <v-overlay absolute class="d-flex flex-column text-center">
                      <div>
                        <v-progress-circular size="75" indeterminate>
                          <span>Loading</span>
                        </v-progress-circular>
                      </div>
                      <div>
                        <!-- <v-btn text dark @click="loading = false" class="mt-3"
                        >Deactivate loading</v-btn
                      > -->
                      </div>
                    </v-overlay>
                  </template>

                  <div class="d-flex bd-highlight">
                    <div class="p-2 flex-fill bd-highlight">
                      {{ documento.nome }}
                    </div>
                    <div class="p-2 flex-fill bd-highlight">Status : ativa</div>
                    <div class="p-2 flex-fill bd-highlight">ações</div>
                  </div>

                  <v-switch
                    v-model="assinar"
                    label="Assinatura digital:"
                  ></v-switch>
                  <div
                    v-if="assinar"
                    class="row justify-content-end align-items-md-end"
                  >
                    <div
                      class="col-md-7 row justify-content-end align-items-md-end"
                    >
                      <treeselect
                        :multiple="false"
                        :options="lista_certificados"
                        placeholder="Selecione um certificado..."
                        v-model="id_certificado"
                        class="col-md-12"
                      />

                      <v-btn
                        @click="assinarDocumento"
                        class="mr-3"
                        color="info"
                      >
                        Assinar documento
                      </v-btn>
                    </div>
                  </div>
                </v-card>
              </template>
            </v-hover>
          </div>
        </div>

        <!-- <div class="col-md-3 mt-2 card">
          <v-jstree
            :data="lista_pastas"
            multiple
            allow-batch
            whole-row
          ></v-jstree>
        </div> -->
      </div>
    </div>

    <v-dialog v-model="modelVersao" max-width="600">
      <template>
        <v-card>
          <v-card-title>Versões</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <b-table
              hover
              Small
              :fields="['nome', 'versao', 'acoes']"
              :items="lista_versao"
              :per-page="perPage"
              :current-page="currentPage"
              id="empresa-table"
              class="table table-vertical-center table-borderless"
              show-empty
              empty-text="Nenhum registro encontrado!"
            >
              <template #head(acoes)><span></span></template>

              <template #cell(acoes)="{ item }">
                <div class="text-right w-100">
                  <button
                    v-show="lista_permissoes_filial.u_Pastas"
                    @click="restaurar(item)"
                    class="btn btn-icon btn-light btn-sm mx-1"
                    v-b-tooltip.hover
                    title="Restaurar"
                  >
                    <i class="fas fa-undo text-primary"></i>
                  </button>
                </div>
              </template>
            </b-table> </v-card-text></v-card></template
    ></v-dialog>
  </div>
</template>

<script>
//   import 'viewerjs/dist/viewer.css'
//   import VueViewer from 'v-viewer'
import JwtService from "@/core/services/jwt.service";
import VuePdf from "vue-pdf";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
// import VJstree from "vue-jstree";
// import Upload from "@/view/components/arquivex/upload.vue";
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import axios from "axios";
export default {
  mixins: [fireAlert],
  name: "Layout",

  components: {
    VuePdf,
    // VJstree,
    // Upload,
    // VueViewer,
  },

  data: function () {
    return {
      selectedItem: 1,
      scrollInvoked: 2,
      modelVersao: false,
      images: [
        "https://picsum.photos/200/200",
        "https://picsum.photos/300/200",
        "https://picsum.photos/250/200",
      ],
      id_certificado: null,
      loading: false,
      uploadDialog: true,
      routers: "",
      subpastas_id: "",
      currentPage: 1,
      currentPageInput: 1,
      perPage: 10,
      achou: "",
      assinar: false,
      token: JwtService.getToken(),
      arquivoData: null, // Variable to store the file data
      host: process.env.VUE_APP_API_URL + "/documento/doc/",
      menus: [
        {
          route: "createDocumento",
          nome: "Atualizar Documento",
          url: "media/svg/icons/Design/Edit.svg",
          ver: true,
        },
        {
          route: "ExcluirFile",
          nome: "Excluir Arquivo",
          url: "media/svg/icons/Files/Deleted-file.svg",
          ver: true,
        },
        {
          route: "download",

          nome: "imprimir ou baixar Arquivo",
          url: "media/svg/icons/Files/Cloud-download.svg",
          ver: true,
        },
      ],
      numPages: 0, // Armazena o número total de páginas do PDF
      currentPage: 1, // Armazena a página atual do PDF
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Arquivex" }]);
    this.sele();
  },
  computed: {
    lista_pastas_doc() {
      return this.$store.state.pastas.lista_pastas_doc;
    },
    mensagem_alert() {
      return this.$store.state.documentos.mensagem_alert;
    },
    documento() {
      return this.$store.state.documentos.documento;
    },
    lista_versao() {
      return this.$store.state.documentos.lista_versao;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    lista_certificados() {
      let cert = Object.values(
        this.$store.state.certificado.lista_certificados
      );
      var data = [];

      for (let i = 0; i < cert.length; i++) {
        data.push({ id: cert[i].id, label: cert[i].nome });
      }
      return data;
    },
  },
  created() {
    this.set_arquivo();
  },
  watch: {
    assinar: function () {
      this.listar_certificados();
    },
    "$route.params.arquivo_id": function () {
      this.loading = true;

      this.$store
        .dispatch("documentos/set_arquivo", this.$route.params.arquivo_id)
        .finally(() => {
          this.loading = false;
        });
      this.set_arquivo();
    },
  },

  methods: {
    sele() {
      this.lista_pastas_doc.forEach((element, key) => {
        if (element.id == this.documento.id) {
          this.selectedItem = key;
          document.getElementById("list-item-" + key).scrollIntoView();
        }
      });
    },
    async irArq(data) {
      await this.$store.dispatch("configEmpresa/atualizar", data);

      this.$router.push({
        name: "viewfile",
        params: { arquivo_id: data.id },
      });
    },
    async teste() {
      await this.$store.dispatch("documentos/logs_download", this.documento.id);

      window.open(
        `${process.env.VUE_APP_API_URL}/documento/doc/${
          this.documento.id
        }?token=${JwtService.getToken()}`,
        "_blank"
      );
    },
    subpastas(route) {
      if (route == "ExcluirFile") {
        this.confirm();
      } else if (route == "download") {
        this.teste();
      } else {
        this.$router.push({
          name: route,
          params: { arquivo_id: this.$route.params.arquivo_id },
        });
      }
    },
    async listar_certificados() {
      this.$store.dispatch("certificado/listar_certificados");
    },
    async set_arquivo() {
      this.loading = true;

      await this.$store
        .dispatch("documentos/set_arquivo", this.$route.params.arquivo_id)
        .finally(() => {
          this.loading = false;
        });

      this.verif();
      await this.$store.dispatch("configEmpresa/atualizar", this.documento);
      // if (this.lista_pastas_doc.length === 0) {
      await this.$store.dispatch(
        "pastas/listar_pastas_doc",
        this.documento.pastas_id
      );
      let ver = false;
      this.menus = this.menus.map((item) => {
        if (item.route == "createDocumento" || item.route == "ExcluirFile") {
          ver = this.documento.apenasVerEditar;
          ver = this.documento.apenasVer;
        } else {
          ver = this.documento.apenasVerEditar && this.documento.apenasVer;
        }
        return {
          ...item, // Isso copia todas as propriedades existentes do item
          ver: ver, // Isso sobrescreve/atualiza a propriedade `ver` com o valor de `this.documento.apenasVer`
        };
      });

      // }

      this.get_arquivo(this.$route.params.arquivo_id);
    },

    async showModalVersao() {
      await this.$store.dispatch(
        "documentos/listar_versao",
        this.documento.versao
      );
      this.modelVersao = true;
    },

    trocar(route) {
      this.$router.push({ name: route });
    },
    show() {
      this.images.push(this.documento.caminho);
      this.$viewerApi({
        images: this.images,
      });
    },

    assinarDocumento() {
      let a = {
        id_documento: this.$route.params.arquivo_id,
        id_certificado: this.id_certificado,
      };
      // this.$store.dispatch("certificado/assinar", a);

      window.open(
        `${process.env.VUE_APP_API_URL}/certificado/assinar/${a.id_documento}/${
          a.id_certificado
        }/?token=${JwtService.getToken()}`,
        "_blank"
      );
    },
    async confirm() {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação deleta uma Pasta no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            this.delete();
          }
        },
      });
    },
    async delete() {
      await this.$store.dispatch(
        "documentos/delete_documento",
        this.$route.params.arquivo_id
      );

      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "arquivex",
      });
    },
    async restaurar(item) {
      await this.$store.dispatch("documentos/restaurar_documento", {
        ida: this.documento.id,
        idv: item.id,
      });
      await this.fireAlert({
        ...this.mensagem_alert,
      });
      this.$router.push({
        name: "viewfile",
        params: { arquivo_id: item.id },
      });
      this.modelVersao = false;
    },
    verif() {
      if (!this.documento.nome_original) {
        this.$router.push({ name: "arquivex" });
      }
    },
    printDocument() {
      // Chame a função de impressão do navegador quando o botão for clicado
      // this.$refs.pdfViewer.print();
      for (let i = 1; i <= this.numPages; i++) {
        this.$refs.pdfViewer.printPage(i);
      }
    },
    async changePage(pageNumber) {
      this.loading = true;
      await this.sleep(300);
      this.currentPage = pageNumber;
      this.currentPageInput = pageNumber;

      this.loading = false;

      // this.$refs.pdfViewer.updatePage(pageNumber);
    },
    async goToPage() {
      this.loading = true;
      await this.sleep(300);
      if (parseInt(this.currentPageInput) > this.numPages) {
        this.currentPage = this.numPages;
        this.currentPageInput = this.numPages;
      } else {
        this.currentPage = parseInt(this.currentPageInput);
      }

      this.loading = false;
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    onDocumentLoad(numPages) {
      this.numPages = numPages;
    },
    async get_arquivo(arquivo_id) {
      this.currentPage = 1;
      this.arquivoData = null;
      this.loading = true;
      try {
        const response = await axios.get(
          this.host + arquivo_id + "?token=" + this.token,
          {
            responseType: "arraybuffer",
          }
        );

        // Convert the arraybuffer to a base64 string
        const base64String = btoa(
          new Uint8Array(response.data).reduce(function (data, byte) {
            return data + String.fromCharCode(byte);
          }, "")
        );

        // Set the arquivoData variable
        this.arquivoData = "data:application/pdf;base64," + base64String;
      } catch (error) {
        console.error("Error fetching file data:", error);
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.scroll {
  overflow-y: scroll;
}
</style>
